@import './colors';

.option-button {
	outline: none;
	border: none;
	
	// background-color: $freckleblue;
	background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
	background-size: 400% 400%;
	background-position: 0% 50%;

	color: white;
	padding: 7px 13px;
	border-radius: 3px;
	font-family: "Signika";
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	transition: 0.25s;
	width: 100%;
	margin-bottom: 10px;
	transition: 0.5s;

	font-size: 13px;

	display: flex;
	justify-content: center;
	align-items: center;
	// flex-direction: column;

	img {
		margin-right: 8px;
		max-height: 15px;
	}
}
.option-button:hover {
	background-position: 100% 50%;
}

.option-button.outline {
	background: linear-gradient(90deg, #3f3f3f, #3f3f3f, #3f3f3f, #3f3f3f, #F1A1C0, #E3A434, #E3A434, #E3A434);
	background-size: 400% 400%;
	background-position: 0% 50%;
	transition: 0.5s;
}

.option-button.outline:hover {
	background-position: 100% 50%;
}

.library-pane-container {
	height: calc(100vh - 65px);
	width: 100%;
	overflow: scroll;

	.pane-header-container {
		width: calc(100% - 20px);
		min-height: 0;
		// height: 120px;
		max-height: 40vh;
		outline: 0;
		border: 0;
		background-color: #1f1f1f;
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		flex-direction: row;
		overflow: hidden;
		border-bottom: 1px solid #111111;
		padding: 10px;

		.right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
		}

		.play-button {
			height: 100%;
			width: 100%;
			position: absolute;
			background-color: transparent;
			outline: none;
			border: none;
			transform: scale(1.0);
			transition: 0.2s;
			cursor: pointer;
			background-color: transparent;

			img {
				height: 50px;
				width: 50px;
			}
		}

		.play-button:hover {
			transform: scale(1.05);
			background-color: rgba(0,0,0,0.15);
		}

		.video-poster {
			width: 65px;
			height: 65px;
			border-radius: 6px;
			object-fit: cover;
			margin: 5px;
			margin-left: 20px;
		}

		.pane-header-details {
			margin-left: 10px;
		}

		.pane-header-text {
			color:white;
			margin: 0;
			font-size: 20px;
			font-weight: bold;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-grow: 1;

			.name {
				
			}
		}

		.trash-container {
			height: 30px;
			width: 30px;
			background-color: transparent;
			opacity: 0.45;
			cursor: pointer;
			transition: 0.25s;
			flex: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 2px;
			margin-left: 5px;

			.trash-icon {
				border-radius: 0;
				object-fit: fill;
				background-color: transparent;
				height:14px;
				width: 14px;
				margin: 0 15px;
				padding: 0;
			}
		}

		.trash-container:hover {
			opacity: 1;
		}
	}

	video {
		width: 100%;
		height: auto;
		max-height: 40vh;
		outline: 0;
		border: 0;
	}

	.footer-container {
		height: 60px;
		width: 100%;
		position: fixed;
		bottom: 0;
		background-color: #222222;

		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	}

	.publish-versions {
		// padding: 20px 30px 0 30px;
		border-top: 1px solid #111111;

		.notReady {
			background-color: #444444;
			cursor: default;
			pointer-events: none;
		}

		h5 {
			padding: 0;
			margin: 0;
			color: white;
		}
		.header {
			padding: 0 32px;
			margin-top: 20px;
			width: calc(100% - 64px);
			height: 30px;
			background-color: #3f3f3f;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		hr {
			outline: none;
			border: none;
			height: 1.5px;
			background-color: rgba(255,255,255,0.1);
		}
		
		.versions-list {
			padding: 20px 30px 0 30px;
			display: flex;
			flex-direction: column;
			color: white;

			p {
				text-align: center;
				font-size: 12px;
				color: #9f9f9f;
				padding: 0;
				margin: 0;
			}

			.zero-state {
				width: 100%;
				padding-top: 10px;
				font-size: 12px;
				color: #9f9f9f;
			}

			.publish-version-item {
				height: 35px;
				cursor: default;
				display: flex;
				align-items: center;
				transition: 0.15s;
				margin: 0;
				padding-left: 5px;
				font-size: 12px;
				color: #9f9f9f;
				justify-content: space-between;

				.left {
					display: flex;
					align-items: center;
				}

				.right {
					display: flex;
					align-items: center;
				}

				.dot {
					background-color: #5f5f5f;
					height: 10px;
					width: 10px;
					border-radius: 5px;
					margin-right: 15px;
					display: flex;
					align-items: flex-start;
					justify-content: center;

					.line {
						width: 2px;
						height: 30px;
						margin-top: 10px;
						background-color: #2f2f2f;
					}
				}

				.dot.last {
					.line {
						display: none;
					}
				}

				.embed {
					outline: none;
					height: 25px;
					border-radius: 12.5px;
					// border: 1px solid white;
					border: none;
					opacity: 0;
					color: white;
					font-family: "Signika";
					font-size: 12px;
					font-weight: bold;
					background-color: transparent;
					cursor: pointer;
					transition: 0.15s;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px 10px;

					img {
						height: 10px;
						margin-right: 5px;
						margin-top:1px;
					}
				}
			}

			.publish-version-item:hover {
				color: white;

				.embed {
					opacity: 0.75;
				}

				.embed:hover {
					opacity: 1.0;
					background-color: #2f2f2f;
				}
			}
		}
	}

	.details {
		margin: 20px 0px;
		display: flex;
		flex-direction: column;

		.option-button {
			outline: none;
			border: none;

			margin: 0 30px;
			
			// background-color: $freckleblue;
			background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
			background-size: 400% 400%;
			background-position: 0% 50%;

			color: white;
			padding: 12px 13px;
			border-radius: 3px;
			font-family: "Signika";
			font-weight: bold;
			text-transform: uppercase;
			cursor: pointer;
			transition: 0.25s;
			width: calc(100% - 60px);
			margin-bottom: 10px;
			transition: 0.5s;

			img {
				margin-right: 8px;
			}
		}
		.option-button:hover {
			background-position: 100% 50%;
		}

		.option-button.outline {
			background: linear-gradient(90deg, #3f3f3f, #3f3f3f, #3f3f3f, #3f3f3f, #F1A1C0, #E3A434, #E3A434, #E3A434);
			background-size: 400% 400%;
			background-position: 0% 50%;
			transition: 0.75s;
		}

		.option-button.outline:hover {
			background-position: 100% 50%;
		}

		.video-options-list {
			padding-top: 20px;
			
			.products-list {
				display: flex;
				flex-direction: column;

				.arrow {
					width: 0; 
					height: 0; 
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;

					border-bottom: 5px solid #8f8f8f;
					transition: 0.2s;
					transform: rotate(180deg);
				}

				.arrow.down {
					transform: rotate(180deg);
				}

				.arrow.up {
					transform: rotate(0deg);
				}

				.ReactCollapse--collapse {
					transition: height 0.15s;
				}

				.section-header {
					color: #7f7f7f;
					font-weight: 700;
					font-size: 14px;
					display: flex;
					align-items: center;
					gap: 10px;
					user-select: none;
					transition: 0.2s;
					cursor: pointer;
					padding: 8px 30px;
					// background-color: #1e1e1e;

					&:hover {
						opacity: 1;
						color: white;

						.icon {
							opacity: 1.0;
						}
					}

					label {
						flex: 1;
						pointer-events: none;
					}

					.icon {
						height: 12px;
						width: 12px;
						flex: 0;
						margin-top: -2px;
						opacity: 0.5;
						transition: 0.2s;
					}
				}

				.items {
					padding: 10px 0px 20px 0px;
				}

				.microsite-item {
					display: flex;
					flex-direction: column;
					justify-content: center;
					gap: 5px;
					cursor: pointer;
					height: 60px;
					transition: 0.2s;
					padding: 0 30px;

					h4 {
						color: white;
						font-size: 14px;
						margin: 0;
						padding: 0;
					}
					h5 {
						color: #8f8f8f;
						font-size: 14px;
						margin: 0;
						padding: 0;
						font-weight: 500;
					}

					&:hover {
						background-color: #2f2f2f;
					}
				}

				.item {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 15px;
					height: 50px;
					padding: 0 30px;

					h4 {
						color: white;
						font-size: 14px;
					}

					img {
						height: 35px;
						width: 35px;
						object-fit: cover;
						background-color: white;
						border-radius: 4px;
					}
				}
			}
		}
	}

	h2 {
		color:white;
		margin: 0;
		padding-bottom: 5px;
	}

	h5 {
		padding: 0;
		margin: 0;
		width: fit-content;
		margin-bottom: 10px;
		color: rgba(255,255,255,0.6);
		user-select: none;
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.updated-label {
		color: #888888;
		font-size:12px;
		padding-top:3px;
		margin-bottom: 5px;
		// padding-bottom: 5px;
		// margin-bottom: 15px;
	}
}