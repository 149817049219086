.text-input-container {
	margin-top:20px;
	height: 70px;
	overflow: hidden;

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  color: rgba(255,255,255,0.2);
	}

	.text-input {
		height: 45px;
		width: calc(100% - 12px);
		background-color: rgba(255,255,255,0.1);
		border: 0;
		padding-left: 10px;
		outline: none;
		margin: 0;
		color: rgba(255,255,255,0.7);
		// border-bottom: 2px solid #333333;
		transition: 0.25s;
		font-size: 14px;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
		border-radius: 4px;
	}

	.text-input:hover {
		color: white;
	}

	.text-input:focus {
		color: white;
		background-color: rgba(255,255,255,0.15);
		box-shadow: rgba(255,255,255,0.2) 0px 0px 15px -10px;
	}

	.text-area {
		min-height: 100px;
		height: calc(100% - 12px);
		width: 100%;
		background-color: transparent;
		border: 0;
		padding-left: 0;
		outline: none;
		margin: 0;
		margin-top:20px;
		color: rgba(255,255,255,0.7);
		border-left: 2px solid #333333;
		transition: 0.25s;
		resize: none;
		padding-left:10px;
		font-family: "Signika";
		font-size: 14px;
	}

	.text-area:hover {
		color: white;
	}

	.text-area:focus {
		color: white;
		border-left: 2px solid #4A90E2;
	}

	.label {
		color: rgba(255,255,255,0.5);
		font-size: 12px;
		height: 12px;
		font-weight: 500;
		transition: 0.25s;
	}

	.labelContainer {
		display: flex;
		flex-direction: row;
		flex-gap: 10px;
		overflow: display;
		padding-bottom: 10px;
	}

}

.text-input-container.fullWidth {
	margin: 30px 0;
	width: 100%;
}