.header-container {
	width: 100%;
	height: 60px;
	min-height: 60px;
	background-color: #222222;
	border-bottom: 2px solid #111111;

	display: flex;
	justify-content: space-between;
	align-items: center;

	// border-bottom:2px solid #121212;
	// margin-bottom:2px;

	// -webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	// -moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	// box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	z-index: 100;

	background-image: url('./img/freckle-logo.svg');
	background-repeat: no-repeat;
	background-position: 25px center;
	background-size: 80px auto;

	color: white;
	font-size: 13px;
	font-weight: 700;

	position: relative;

	transition: 0.25s;

	.back-button {
		outline: none;
		border: none;
		background: none;
		color: white;
		display:flex;
		justify-content: center;
		align-items: center;
		height:40px;
		width: 40px;
		border-radius: 20px;
		margin-left: 5px;
		transition: 0.25s;
		cursor: pointer;

		img {
			opacity: 0.15;
			margin-left: -1px;
			transition: 0.25s;
		}
	}

	.back-button:hover {
		background-color: rgba(255,255,255,0.025);
		img {
			opacity: 1;
		}
	}

	.title {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display:flex;
		justify-content:center;
		align-items:center;
		flex-direction: column;
		background-color: transparent;
		pointer-events: none;
		font-size: 15px;

		.date {
			color: #666666;
			font-size: 12px;
			margin-top: 5px;
		}
	}

	.menu-right {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 100%;

		.preview-button {
			outline: none;
			border: none;
			
			font-size: 13px;

			min-width: 170px;
			
			// background-color: $freckleblue;
			background: linear-gradient(90deg, #4A90E2, #4A90E2, #4A90E2, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
			background-size: 400% 400%;
			background-position: 0% 50%;

			color: white;
			border-radius: 3px;
			font-family: "Signika";
			font-weight: bold;
			text-transform: uppercase;
			cursor: pointer;
			width: 100%;
			transition: 0.5s;

			height: 30px;
			padding: 0 20px;
			margin-right: 20px;
			color: white;

			img {
				margin-right: 8px;
			}
		}
		.preview-button:hover {
			transition: 0.5s;
			background-position: 100% 50%;
		}

		.preview-button.disabled {
			background: none;
			background-color: #4f4f4f;
			color: #7f7f7f;
		}

		.menu-bar {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			button {
				width: 50px;
				height: 50px;
				border-radius: 25px;
				outline: 0;
				border: 0;
				background-color: transparent;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}

			button:hover{
				background-color: rgba(255,255,255,0.025);
				transition: 0.25s;
			}

			.library-button {
				img {
					height: 12px;
				}
			}

			.products-button {
				img {
					height: 16px;
					margin-top:-2px;
				}
			}

			.stage-button {
				img {
					height: 13px;
					margin-top:-1px;
				}
			}
		}

		.account {
			float:right;
			padding: 0 20px;
			margin-left: 10px;
			line-height: 30px;
			cursor: pointer;
			transition: 0.25s;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			.account-avatar {
				float: left;
				width: 30px;
				height: 30px;
				display:flex;
				justify-content: center;
				align-items: center;
				border-radius: 15px;
				background-color: #E73C7E;
				text-transform: uppercase;
				margin-right:10px;
				font-weight: 700;
				font-size: 16px;
				margin: 0 auto;
			}

			.dropdown {
				position: absolute;
				right: 0;
				top: 60px;
				// height: 50px;
				width: 200px;
				background-color: #444444;
				display: none;

				.item {
					display:flex;
					align-items: center;
					height: 50px;
					padding-left: 20px;
					transition: 0.25s;
					border-top: 1px solid #555555;
					background-color: rgba(255,255,255,0.025);
				}

				.item:hover {
					background-color: rgba(255,255,255,0.25);
				}
			}
		}

		.account:hover {
			// background-color: rgba(255,255,255,0.025);
			transform: scale(1.05);

			.dropdown {
				display: block;
			}
		}
	}
}

.header-container.back {
	background-position: 50px center;
}