.player-container {
	display: flex;
	flex-direction: column;
	height:0;
	min-height: 220px;
	flex-grow: 1;
	overflow: hidden;
	transition: opacity 0.5s, transform 0.5s;
	min-height: 250px;
	
	.vjs {
		width: 100%;
		height: 100%;
		overflow: hidden;
		outline: 0;
		border: 0;
	}

	video {
		width: auto;
		height: 100%;
		width: 100%;
		object-fit: contain;
		pointer-events: none;
		outline: none; 
		border: 0;
	}

	.video-container {
		position: relative;
		overflow: hidden;
		height: 100%;
		min-height: 200px;
	}

	.freckle-layer {
		position: absolute;
		top: 50%;
		left: 50%;
		cursor: crosshair;
		// background-color: rgba(255,0,0,0.1);
		z-index: 1000;

		.inner-freckle-layer {
			width: 100%;
			height: 100%;
			// outline: 2px solid blue;
		}
	}

	.info {
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: row;
		margin: 0 auto;
		padding-top: 10px;
		color: white;
		margin-bottom: 20px;
		font-size: 14px;
		color: #2f2f2f;

		.left {
			width: calc(50% - 70px);
			min-width: 120px;
			text-align: left;
			user-select: none;
			overflow: hidden;
		}

		.right {
			width: calc(50% - 70px);
			min-width: 120px;
			text-align: right;
		}

		.currenttime {
			color: white;
			padding-left: 10px;
			font-weight: 400;
		}

		.totaltime {
			color: #4f4f4f;
			padding-right: 10px;
			font-weight: 400;
		}
	}

	.controls {
		display:flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		width: 90px;
		min-height: 25px;
		flex: 0;

		.control {
			background-color: transparent;
			outline: 0;
			border: 0;
			cursor: pointer;
			height: 20px;
			width: 20px;
			background-size: 15px 15px;
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 5px;
		}
		.control.play {
			background-image: url('./img/play.svg');
		}
		.control.pause {
			background-image: url('./img/pause.svg');
		}
		.control.rev {
			background-image: url('./img/rev.svg');
		}
		.control.fwd {
			background-image: url('./img/fwd.svg');
		}
	}
}

.freckle {
	-webkit-animation-timing-function: linear;
	animation-play-state: paused;
	
	display: flex;
	justify-content: center;
	align-items: center;

	position: absolute;

	user-select: none;
	
	margin-left: -13px;
	margin-top: -13px;


	.outer {
		height: 20px;
		width: 20px;

		border: 3px solid transparent;

		border-radius: 50%;
		background-color: transparent;
		
		// display: inline-block;
		display: flex;
		justify-content: center;
		align-items: center;

		transition:0.2s background-color ease;

		cursor: grab;

		.inner {
			background-color: transparent;
			height: 6px;
			width: 6px;
			border: 5px solid rgba(255,255,255,1);
			border-radius: 100px;
			margin: 0 auto;
			box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
			transition:0.2s background-color ease;
		}
	}
}

.freckle:hover {
	.outer {
		.inner {
			border: 5px solid rgba(52, 172, 224, 1);
		}
	}
}

.freckle.selected {
	.outer {
		border: 3px solid rgba(52, 172, 224, 0.2);

		.inner {
			border: 5px solid #4A90E2;
			box-shadow: none;
		}
	}
}

.freckle.selected:hover {
	.outer {
		border: 3px solid rgba(52, 172, 224, 0.5);

		.inner {
			border: 5px solid #4AB7E2;
		}
	}
}
