.container {
	width: 100vw;
	min-height: 100vh;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: "Signika";

	animation: enterContainerAnim 500ms normal forwards;

	@keyframes enterContainerAnim {
	  0% {
	    opacity: 0;
	  }
	  100% {
	    opacity: 1.0;
	  }
	}

	.reset-container {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.icon {
		height: 60px;
		margin: 20px;
	}

	p {
		color: white;
		font-family: "Signika";
		font-size: 18px;
		color: white;
		padding:0;
		margin: 0;
		max-width: 500px;
		line-height: 35px;
		text-align:center;
	}

	.forgot {
		outline: none;
		border: none;
		background-color: transparent;
		color: rgba(255,255,255,0.2);
		font-family: "Signika";
		font-size: 12px;
		text-decoration: underline;
		padding: 0;
		margin-top: 20px;
		position: relative;
		transition: 0.25s;
		cursor: pointer;
	}

	.forgot:hover {
		color: white;
	}

	.error-container {
		height: 30px;
		width: 100%;
		position: absolute;
		top: 10px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.25s;

		.error-message {
			border-radius: 4px;
			padding: 5px 20px;
			background-color: #E73C7E;
			color: white;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 15px;
				width: 15px;
				padding-right: 10px;
			}
			animation: enterErrorAnim 500ms normal forwards;

			@keyframes enterErrorAnim {
			  0% {
			    opacity: 0;
			    margin-top: -100px;
			  }
			  100% {
			    opacity: 1.0;
			    margin-top: 0;
			  }
			}
		}
	}

	.logo {
		background-image: url('./img/freckle-logo.svg');
		background-position: center;
		background-repeat: no-repeat;

		height: 50px;
		min-height: 50px;
		width: 200px;
		margin-bottom: 10px;
	}

	.form-container {
		width: 100%;
		max-width: 450px;
		padding: 10px;

		display: flex;
		flex-direction:column;
		align-items:center;
		justify-content: center;
	}

	form {
		width: 100%;
		text-align: left;
	}

	hr {
		background-color: white;
		opacity: 0.05;
		border:none;
		outline: none;
		height: 1px;
		margin: 20px 0;
	}

	.createButton {
		opacity: 0.25;
		outline: 0;
		border: 0;
		height: 45px;
		width: calc(100% - 2px);
		padding: 0 20px;
		
		background: linear-gradient(90deg, transparent, transparent, transparent, white, white, white, white, white);
		background-size: 400% 400%;
		background-position: 0% 50%;

		border:1px solid white;

		border-radius: 4px;

		display:flex;
		align-items: center;
		justify-content:center;

		margin: 0 auto;
		// margin-top: 40px;

		font-size: 12px;
		letter-spacing: 0.5px;
		font-weight: 500;

		cursor: pointer;
		font-weight: 700;
		color: white;
		transition: 0.5s;
	}

	.createButton:hover {
		opacity: 1;
		background-position: 100% 50%;
		color: black;
		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	}

	.submitButton {
		outline: 0;
		border: 0;
		height: 45px;
		width: 100%;
		padding: 0 20px;
		
		background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
		background-size: 400% 400%;
		background-position: 0% 50%;

		border-radius: 4px;

		display:flex;
		align-items: center;
		justify-content:center;

		margin: 0 auto;
		margin-top: 40px;

		font-size: 12px;
		letter-spacing: 0.5px;
		font-weight: 500;

		cursor: pointer;
		font-weight: 700;
		color: white;
		transition: 0.5s;

		img {
			float:left;
			padding-right:15px;
			margin-left: -20px;
			height: 15px;
		}
	}

	.submitButton:hover {
		background-position: 100% 50%;
		color: white;
		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	}
}