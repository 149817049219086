@import './colors';

.path-button {
	font-family: "Signika";
	font-weight: bold;
	font-size: 12px;
	outline: none;
	border: none;
	background-color: #F74743;
	border: 2px solid transparent;
	border-radius: 3px;
	color: white;
	cursor: pointer;
	margin: 5px 0;
	// padding: 0;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 20px;
	transition: 0.5s;
	// box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);

	img {
		 height: 12px;
		 margin-right: 5px;
	}
}

.path-button:hover {
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
}

.path-button.closed {
	background-color: $freckleblue;
	color: white;
}

.delete-keyframe-button {
	border: none;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	opacity: 0.5;
	transition: 0.2s;
	margin-left: 10px;

	&:hover {
		opacity: 1.0;
	}

	img {
		pointer-events: none;
	}
}

.keyframe-tooltip-time {
	color: #8f8f8f;
}

.stage-container {
	position: relative;
	color:white;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;

	.menu-toolbar {
		width: calc(100% - 10px);
		height: 40px;
		background-color: #1f1f1f;
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		padding-left: 10px;
		z-index: 1000;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;

		.left {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
		}

		.right {
			margin-right: 15px;
			margin-top: 5px;
		}
	}

	.keyframe-tooltip {
		display: flex;
		flex-direction: column;
	}

	.names-container {
		height: 100%;
		width: 250px;
		min-width: 250px;
		background-color: #222222;
		// -webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);
		// -moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);
		// box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);
		position: relative;
		z-index: 100;
		margin-right: 2px;
	}

	.stage-timeline-container {
		width: 100%;
		height: 100%;
		overflow-x: scroll;
		overflow-y: visible;
		position: relative;
		user-select: none;

		&::-webkit-scrollbar {
		  background-color: transparent;
		  height: 17px;
		  cursor:pointer;
		}

		&::-webkit-scrollbar-track {
		  background-color: #1e1e1e;
		}

		&::-webkit-scrollbar-thumb {
		  border-radius: 100px;
		  border: 4px solid transparent;
		  background-clip: content-box;
		  background-color: rgba(255,255,255,0.1);
		  transition: 0.25s;
		  cursor:pointer !important;

		  &:hover {
		  	background-color: #3f3f3f;	
		  }
		}

		.timeline-overlay {
			position: absolute;
			top: 0;
			left: 0;
			height: calc(100% - 40px);
			z-index: 200;
			pointer-events: none;	
		}

		.playhead {
			position: absolute;
			background-color: transparent;
			height:calc(100% + 20px);
			margin-top:-20px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-left: -4.5px;

			.topper {
				width: 9px;
				height: 9px;
				background-color: #4A90E2;
			}

			.line {
				width: 1px;
				height: 100%;
				background-color: #4A90E2;
			}
		}

		/*.playhead::before{
		   content: '';
		   display: inline-block;
		   width: 9px;
		   height: 9px;
		   background-color: #4A90E2;

		   pointer-events: none;

			position: relative;
			top: -15px;
			left:-3.5px;
		}*/
	}

	.timeline-content::-webkit-scrollbar{
		display: none;
	}

	.timeline-content {
		position: absolute;
		top: 40px;
		left: 0;
		/*width: 100%;*/
		height: calc(100% - 40px);
		/*border: 1px dotted blue;*/
		overflow-y: scroll;
		z-index: 25;
	}

	.toolbar {
		height: 40px;
		width: 250px;
		display: flex;
		justify-content: flex-start;
		z-index: 15;
		background-color: #1f1f1f;
		position: absolute;

		.add-product {
			outline: 0;
			border: 0;
			
			/*background-color: #222222;*/
			background: linear-gradient(90deg, #333333, #333333, #404040, #404040, #c5d7ed, #4A90E2, #4A90E2, #4A90E2);
			background-size: 400% 400%;
			background-position: 0% 50%;

			color:white;
			border-radius: 4px;
			height: 30px;
			font-weight: bold;
			cursor: pointer;
			transition: 0.5s;
			margin-left: 10px;
			padding-right: 12px;

			-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);
			-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);
			box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.15);

			img {
				height: 15px;
				float: left;
				margin-right: 10px;
				margin-left:2px;
			}
		}

		.add-product:hover {
			background-position: 100% 50%;
			color: white;
		}
	}

	.active-stage {
		display: flex;
		flex-direction: row;
		height: 0;
		flex-grow: 1;
	}
}

.group-name-panels-container::-webkit-scrollbar{
  display: none;
}

.group-name-panels-container {
	position: absolute;
	top: 40px;
	left: 0;
	width: 100%;
	height: calc(100% - 40px);
	overflow: scroll;
	transition: background-color 0.5s ease;

	.placeholder {
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 13px;
		line-height: 22px;
		color: #9f9f9f;
		user-select: none;

		h3 {
			margin: 0;
			padding: 0;
		}

		p {
			width: 70%;
		}

		.blob {
		  border-radius: 50%;
		  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
		  margin: 10px;
		  height: 15px;
		  width: 15px;
		  transform: scale(1);
		  background: rgba(52, 172, 224, 1);
		  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
		  animation: pulse-blue 2s infinite;
		  margin-bottom: 5px;
		}

		@keyframes pulse-blue {
		  0% {
		    transform: scale(0.95);
		    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
		  }
		  
		  70% {
		    transform: scale(1);
		    box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
		  }
		  
		  100% {
		    transform: scale(0.95);
		    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
		  }
		}

	}

	.product-image {
		height: 35px;
		width: 35px;
		background-color: white;
		margin-right: 15px;
		object-fit: contain;
		border-radius: 4px;
	}

	.name-panel {
		padding-left:20px;
		height: 55px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		// border-bottom: 2px solid #121212;
		margin-bottom: 2px;
		font-size: 13px;
		user-select: none;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right:20px;
		background-color: transparent;
	}

	.product-name {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
	}

	.name-panel:hover {
		background-color: rgba(255,255,255,0.025);
	}

	.name-panel.selected {
		background-color: $freckleblue;
	}
}

.group-panels-container {
	min-height: 100%;

	.panel {
		height: 55px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		border-bottom: 2px solid transparent;
		position: relative;;
	}

	.panel.selected {
		/*background-color: #171717;*/
	}

	.section {
		position: absolute;
		border-left: 1px solid transparent;
			
		background-color: #3f3f3f;
		height: 2.5px;
		margin-left:-5px;

		display: flex;
		justify-content: flex-end;
		align-items: center;
		overflow: visible;
		// border-radius: 6px;
		transition: 0.1s background-color;
	}

	.section:hover {
		// background-color: $stage-item-blue-hover;
		cursor: pointer;
	}

	.section.start {
		 background-color: transparent;
	}

	.click-area {
		display: block;
		height: 6px;
		width: 6px;
		cursor: pointer;
		transform: scale(0.9);
		transition: 0.2s;
		z-index: 100;
		position: absolute;
		right: -5px;
		border: 2px solid transparent;
	}

	.click-area.selected {
		border: 2px solid transparent;
		background-color: #4da0ff !important;
		box-shadow: 0px 0px 12px 0px #4da0ff;
	}

	.click-area.start {
		border-radius: 0px;
		transform: rotateZ(45deg);
		background-color: #7ED321;
	}

	.click-area.tween {
		border-radius: 5px;
		background-color: white;
	}

	.click-area.end {
		border-radius: 0px;
		background-color: #F74743;
	}

	.click-area:hover {
		// transform: scale(1.25);
		border: 2px solid transparent;
	}
}

.ticks-container {
	z-index: 30;
	height: 39px;
	display: flex;
	justify-content: flex-start;
	align-items: top;
	flex-direction: row;
	cursor: pointer;
	pointer-events: all;
	background-color: #171717;
	// border-top: 2px solid #111;
	// border-bottom: 1px solid rgba(255,255,255,0.1);

	.tick {
		height: 12px;
		border-left: 1px solid rgba(255,255,255,0.2);
		overflow: visible;
		margin-top:22.5px;
		text-align: left;
		pointer-events: none;

		display: flex;
		justify-content: flex-start;
		align-items: top;
		flex-direction: row;

		position: relative;

		.subtick {
			height: 50%;
			width: calc(25% - 1px);
			border-right: 1px solid #979797;
			opacity: 0.25;
		}

		.subtick.hidden {
			opacity: 0;
		}

		.subsubtick {
			height: 50%;
			width: calc(50% - 1px);
			border-right: 1px solid #979797;
			opacity: 0.8;
		}

		.subsubtick.hidden {
			opacity: 0;
		}

		label {
			text-align: center;
			position: absolute;
			display: block;
			bottom: 15px;
			width: 100px;
			margin-left:-50px;
			color: #979797;
			font-weight: 300;
			font-size: 12px;
		}
	}

	.tick.second {

	}
}


