@import './styles/colors';

@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;600;700&display=swap');


.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  font-family: "Signika";
  display:flex;
  flex-direction: column;
}

.errorsOverlay {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 80px);
  pointer-events: none;
  background-color: transparent;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.messageBox {
  color: white;
  min-height: 30px;
  max-width: 500px;
  width: fit-content;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px 20px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
  font-family: "Signika";
  font-size: 0.9em;
  font-weight: 700;

  img {
    margin-right: 10px;
  }
}

.messageBox.error {
  background-color: #E73C7E;
}

.messageBox.success {
  background-color: #7ED321;
}

.messageBox.progress {
  background-color: #2f2f2f;
  width: 350px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  // padding: 15px 0px;
  padding: 0;

  .contents {
    padding: 15px 10px;
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

      h4 {
        color: white !important;
        margin: 0 10px;
        padding: 5px 0;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;

        img {
          height: 15px;
        }
      }

    .progress {
        font-size: 13px;
        padding: 5px 0;
        font-weight: regular;
        color: #7f7f7f;
        margin: 0 10px;
      }

      .bar-container {
        width: calc(100% - 20px);
        height: 4px;
        border-radius: 2px;
        background-color: #1f1f1f;
        overflow: hidden;
        margin: 5px 10px;

        .bar {
          background-color: $freckleblue;
          height: 100%;
        }
      }
  }

}

.freckle-menu {

}

.freckle-menu-item {
  font-family: "Signika" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.freckle-menu-item.red {
  color: red !important;
}

.main-content {
  display:flex;
  flex-direction: row;
  flex-grow: 1;
}

.left-pane {
  display:flex;
  flex-direction: column;
  flex-grow: 1;
}

.right-pane {
  width: 350px;
  height: 100%;
  background-color: #222222;
}

body::-webkit-scrollbar{
  display: none;
}

div::-webkit-scrollbar {
  background-color: transparent;
  height: 3px;
  width: 3px;
}
 
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: transparent;
}
 
div::-webkit-scrollbar-thumb {
  background-color: #555555;
  outline: none;
  border: none;
}

div::-webkit-scrollbar-corner {
  background-color: transparent;
}

.msg-enter {
  opacity: 0;
  transform: scale(0.9);
}
.msg-enter-active {
  opacity: 1;
  transition: 500ms ease-in;
  transform: scale(1);
}
.msg-exit {
  opacity: 1;
  transform: scale(1);
}
.msg-exit-active {
  opacity: 0;
  transition: 500ms ease-in;
  transform: scale(0.9);
}
