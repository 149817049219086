.embed-video-super-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;

	.embed-icon {
		height:25px;
	}

	input[type="file"] {
	    display: none;
	}

	.custom-file-preview {
		height: 35px;
		font-family: "Signika";
		font-size: 12px;
		font-weight: 700;
		color: white;
		transition: 0.35s;
		display:flex;
		justify-content: left;
		align-items: left;
		line-height: 35px;
		margin-top:15px;
		margin-bottom: 30px;

		a {
			margin-left: 15px;
			color: #3781D8;
			text-decoration: none;
		}
	}

	.custom-file-upload-small {
		font-size: 8px;
	}

	.custom-file-upload {
		max-width: 150px;
		border-radius: 4px;
		outline: 0;
		border: 0;
		background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
		background-size: 400% 400%;
		background-position: 0% 50%;
		height: 35px;
		font-family: "Signika";
		font-size: 12px;
		font-weight: 700;
		color: white;
		transition: 0.35s;
		padding: 0 30px;
		display:flex;
		justify-content: center;
		align-items: center;
		line-height: 35px;
		margin-top:15px;
		margin-bottom: 30px;
	}

	.custom-file-upload:hover {
		cursor: pointer;
		background-position: 100% 50%;
		color: white;
		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	}


	.saving-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
		backdrop-filter: blur(2.5px);
		z-index: 20000;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: all;
		opacity: 1;
		transition: 0.25s;
	}

	.saving-overlay.hidden {
		pointer-events: none;
		opacity: 0;
	}

	.embed-video-container {
		pointer-events: all;
		width: 40vw;
		height: 60vh;
		min-height: 400px;
		background-color: #222222;
		color: white;
		padding: 20px 40px 0px 40px;
		position: relative;

		.editable-content {
			overflow: scroll;
			width: 100%;
			height: calc(100% - 65px);
		}

		.notice-container {
			font-family: "Signika";
			font-size: 14px;
			display: flex;
			flex-direction: column;

			padding:5px 0 5px 10px;
			/*margin: 0 43px;	*/

			p {
				margin: 0;
				padding: 0;
				padding-bottom: 30px;
				line-height: 25px;
				color: #888888;
			}
		}

		.options-container {
			font-family: "Signika";
			font-size: 14px;
			display: flex;
			flex-direction: column;

			padding:10px 0 5px 10px;
			/*margin: 0 43px;*/

			h4 {
				margin: 25px 0 10px 0;
			}

			input {
				cursor: pointer;
			}

			div {
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 30px;

				label {
					margin-left: 10px;
					display: inline-block;
					color: #888888;
				}
			}
		}

		.code-area {
			line-height: 25px;
			outline: none;
			border: none;
			background-color: transparent;
			color: white;
			font-size: 15px;
			font-family: "Courier";
			border-left: 4px solid #333333;
			padding:5px 0 5px 10px;
			/*margin: 0 40px;*/
			position: relative;
		}

		.copy-code-button {
			outline: 0;
			border: 0;
			background-color: transparent;
			position: absolute;
			bottom: -25px;
			right: 0px;
			cursor: pointer;
			opacity: 0.5;
			transition: 0.25s;
			height:25px;
			width:25px;
			overflow: hidden;
			white-space: nowrap;
			display:flex;
			flex-direction: row;
			align-items: center;

			span {
				color: white;
				white-space: nowrap;
				margin-left:10px;
				height: 20px;
				display:flex;
				flex-direction: row;
				align-items: center;
				font-family: "Signika";
				font-size: 12px;
			}
		}

		.copy-code-button:hover {
			opacity: 1;
			width: 140px;
		}

		.footer-container {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 65px;
			// -webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
			// -moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
			// box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);

			display: flex;
			justify-content: space-between;
			align-items: center;

			z-index: 10000;
		}

		.done-button {
			margin-right: 15px;
			height: 35px;
			padding: 0 20px;
			outline: 0;
			border: 0;
			background-color: #4A90E2;
			font-size: 11px;
			font-weight: bold;
			color: white;
			cursor: pointer;
			transition: 0.2s;
			pointer-events: all;
			border-radius: 4px;

			font-size: 12px;
			letter-spacing: 0.5px;
			font-weight: 500;

			cursor: pointer;
			font-weight: 700;
			color: white;
		}

		.done-button:hover {
			color: #4A90E2;
			background-color: white;
		}

	}

	h3, h2, h1 {
		margin-bottom: 30px;
		display:flex;
		align-items:center;

		img {
			float:left;
			height: 35px;
			padding-right: 15px;
		}
	}
}