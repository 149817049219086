.templates-pane {
	color: white;
	display: flex;
	flex-direction: row;
	// position: relative;
	animation: enterLoaderAnim 500ms normal forwards;
	height: calc(100% - 4px);
	font-family: "Signika";

	.overlay-pane {
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: calc(100%);
		background-color: rgba(0,0,0,0.75);
		opacity: 1;
		pointer-events: default;
		transition: 0.5s;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.overlay-pane.hidden {
		pointer-events: none;
		opacity: 0;
	}

	.templates-list {
		width: 100%;
		border: none;
		outline: none;
		font-size: 0.8em;
		border-collapse: collapse;
		background-color: transparent;

		display: flex;
		flex-direction: column;
		gap: 10px;

		.col.title {
			width: 50%;
		}

		.col {
			width: 25%;

			.content {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				margin-top: 1px;

				.action {
					visibility: hidden;
					border: none;
					outline: none;
					background-color: transparent;
					cursor: pointer;
					opacity: 0.4;
					transition: opacity 0.2s;
					margin-left: 10px;

					img {
						pointer-events: none;
					}
				}

				.action:hover {
					opacity: 1;
				}
			}
		}

		.col:hover {
			.content {
				.action {
					visibility: visible;
				}
			}
		}

		tbody {
			tr {
				cursor: pointer;
				height: 35px;
				transition: 0.15s;
			}
			
			tr:hover {
				background-color: #3f3f3f;
				.col.title {
					// text-decoration: underline;
				}
			}
		}

	}

	.zero-state {
		color: white;
		width: 100%;
		height: 90%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		.zero-state-icon {
			height: 50px;
			opacity: 0.15;
			margin-bottom: 20px;
		}

		h3 {
			padding: 0;
			margin: 0;
			padding-bottom: 10px;
			padding-top: 10px;
			color: white;
		}


		p {
			margin: 0;
			padding: 0;
			margin-bottom: 30px;
			font-size: 1.0em;
			max-width:  400px;
			text-align: center;
			line-height: 1.5em;
			color: #7f7f7f;
		}
	}
}

.middle-pane {
	padding: 30px 80px;
	flex-grow: 1;
	width: 100%;
	overflow: scroll;
	padding-bottom: 100px;

	.templates-header {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;

		// button {
		// 	text-decoration: none;
		// 	color: white;
		// 	margin: 0;
		// 	margin-bottom:0px;
		// 	margin-right: 10px;
		// 	outline: 0;
		// 	border: 0;
		// 	height: 45px;
		// 	border-radius: 4px;
		// 	cursor: pointer;
		// 	transition: 0.5s;
		// 	font-size: 15px;
		// 	font-weight: bold;
		// 	letter-spacing: 0.5px;
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	text-transform: uppercase;
		// 	background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
		// 	background-size: 400% 400%;
		// 	background-position: 0% 50%;
		// 	padding: 0 20px 0 17px;
		// 	border-radius: 100px;
		// 	font-family: "Signika";

		// 	img {
		// 		height: 18px;
		// 		margin-right: 12.5px;
		// 	}
		// }

		// button:hover {
		// 	background-position: 100% 50%;
		// 	color: white;
		// 	-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		// 	-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		// 	box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		// }

		h2 {
			display: flex;
			align-items: center;

			img {
				padding-right: 10px;
				height: 18px;
			}
		}
	}

}