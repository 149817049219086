.picker-menu {
	position: relative;
	font-size: 0.9em;
	user-select: none;
	border:1px solid transparent;

	label {
		color: black;
	}

	.picker-row {
		display: flex;
		flex-direction: row;
	}

	.picker-select-box {
		background-color: #333333;
		color: white;
		cursor: pointer;
		padding: 0 15px;
		min-height: 40px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-grow: 1;
		margin: 0;
		margin-right: 10px;
		border-radius: 4px;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;

		.arrow {
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			margin-left: 10px;

			border-bottom: 5px solid #8f8f8f;
			transition: 0.2s;
			transform: rotate(180deg);
		}

		.arrow.down {
			transform: rotate(180deg);
		}

		.arrow.up {
			transform: rotate(0deg);
		}
	}

	.picker-select-box.selected {
		border-radius: 4px 4px 0 0;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
	}

	.select-box.selected {

	}

	.picker-options-container  {
		position: absolute;
		z-index: 100;
		background-color: #2f2f2f;
		overflow: scroll;
		overflow-x: hidden;
		color: white;
		top: 40px;
		left: 0;
		min-width: calc(100% - 10px);
		border-radius: 0 0 4px 4px;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
		max-height: 0px;
		transition: 0.15s;

		.item {
			min-height: 30px;
			width: 100%;
			transition: 0.2s;
			display: flex;
			align-items: center;
			padding: 5px 20px;
			user-select: none;
			cursor: pointer;
		}
		.item:hover {
			background-color: #333;
		}
	}

	.picker-options-container.selected {
		max-height: 400px;
	}
}


