.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;

  margin-bottom:10px;
  width: 120px;
}

.range::-webkit-slider-runnable-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-fill-upper { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip { display: none; /* display and visibility only */ }

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  margin-top: -7px;
  cursor: pointer;
  transition: 0.25s;
  transform: scale(1);
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range:hover::-webkit-slider-thumb {
  outline: none;
  transform: scale(1.1);
  background-color: #6C737E;
}

.range::-ms-thumb { 
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px; 
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}
