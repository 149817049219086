.slider-container {
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	label {
		color: #7f7f7f;
		font-family: "Signika";
		font-weight: 700;
		font-size: 13px;
		padding-bottom: 10px;
	}
}

.button-text-input {
	outline: 0;
	border: 0;
	background-color: #2f2f2f;
	height: 30px;
	color: white;
	font-family: "Signika";
	font-size: 0.7em;
	padding: 0 10px;
}

.value-input {
	outline: 0;
	border: 0;
	background-color: #2f2f2f;
	width: 30px;
	height: 22px;
	margin-left: 5px;
	color: white;
	font-family: "Signika";
	font-size: 0.7em;
	padding: 0 5px;
}