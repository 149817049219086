.account-picker-container {
	position: relative;
	user-select: none;
	cursor: pointer;

	.picker-contents {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		max-width: 90%;
		overflow: hidden;

		img {
			height: 25px;
			float: left;
			margin-right: 15px;
			border-radius: 4px;
			background-color: white;
		}

		.text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			width: 100%;
			overflow: hidden;

			.label {
				font-size: 12px;
				font-weight: bold;
				color: #8f8f8f;
			}

			.value {
				color: white;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				padding: 0;
				margin: 0;
				text-align: left;
			}
		}
	}

	.selected-account-container {
		height: 65px;
		width: calc(100% - 40px);
		// background-color: #2f2f2f;
		color: white;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0 20px;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 10px;
			width: calc(100% - 20px);
			height: 0.5px;
			background-color: #2f2f2f;
		}

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 10px;
			width: calc(100% - 20px);
			height: 0.5px;
			background-color: #2f2f2f;
		}

		.arrow {
			width: 0; 
			height: 0; 
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;

			border-bottom: 5px solid #8f8f8f;
			transition: 0.2s;
			transform: rotate(180deg);

			margin-right: 5px;
		}

		.arrow.down {
			transform: rotate(180deg);
		}

		.arrow.up {
			transform: rotate(0deg);
		}
	}

	.account-options-container {
		position: absolute;
		top: 65px;
		width: fit-content;
		max-width: 350px;
		min-width: 350px;
		background-color: #2f2f2f;
		max-height: 0;
		transition: 0.25s;
		overflow: scroll;
		z-index: 100;
		// border-bottom: 1px solid #2f2f2f;
		opacity: 0;
		border-radius: 0 6px 6px 0px;
		box-shadow: rgba(0,0,0,0.25) 0 2px 10px;

		&::-webkit-scrollbar {
		  display: none;
		}

		.item {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			height: 50px;
			color: white;
			display: flex;
			align-items: center;
			transition: 0.25s;

			span {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			
			img {
				height: 25px;
				margin-right: 15px;
				margin-top: 3px;
				border-radius: 4px;
				background-color: white;
				margin-left: 20px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
			}
		}

		.item:hover {
			background-color: #3f3f3f;	
		}
	}

	.account-options-container.open {
		padding: 5px 0;
		max-height: calc(100vh - 200px);
		opacity: 1;
	}
}