.help-widget {
	height: 12px;
	width: 12px;
	border-radius: 6px;
	background-color: white;
	color: black;
	font-family: "Signika";
	font-weight: 700;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.25;
	transition: 0.25s;
	margin-left: 10px;
	user-select: none;
	cursor: pointer;
}

.help-widget:hover {
	opacity: 1;
	transform: scale(1.1);
}