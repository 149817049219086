.swatch-container {
	display: flex;
	flex-direction: row;

	.swatch {
		position: relative;
		cursor: pointer;
		height: 40px;
		width: 60px;
		// border: 1px solid #9f9f9f;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
		border-radius: 4px;
		box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
	}

	.swatch:hover {
		
	}

	.hex-input {
		outline: 0;
		border: 0;
		background-color: #2f2f2f;
		width: 60px;
		margin-left: 5px;
		color: white;
		font-family: "Signika";
		font-size: 0.7em;
		padding: 0 10px;
		box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
		border-radius: 4px;
	}
}