@import './colors';
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;600&display=swap');

@keyframes enterLibAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

.library-container {
	display:flex;
	flex-direction: column;
	height: 100%;
	font-family: "Signika";
	animation: enterLibAnim 1000ms normal forwards;

	.publishing-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparent;

		.modal {
			pointer-events: all;
			width: 200px;
			height: 120px;
			background-color: #222222;
			color: white;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			h4{
				margin: 0;
				padding: 0;
				padding-bottom: 30px;
				color: rgba(255,255,255,0.75);
			}
		}
	}

	.loading-indicator {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
		// backdrop-filter: blur(2.5px);
		display: flex;
		flex-direction: column;
		line-height: 60px;
		font-size: 14px;
		font-weight: 500;
		justify-content: center;
		align-items: center;
		color: white;
		z-index: 1000;
	}

	.overlay-pane {
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: calc(100%);
		background-color: rgba(0,0,0,0.7) !important;
		opacity: 1;
		pointer-events: default;
		transition: 0.25s !important;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.overlay-pane.hidden {
		pointer-events: none;
		opacity: 0;
	}

	.main-content {
	  display:flex;
	  flex-direction: row;
	  flex-grow: 1;
	  overflow: hidden;
	}

	.lib-left-pane {
		display: flex;
		flex-direction: column;
		width: 250px;
		min-width: 250px;
		height: 100vh;
		text-align: center;
		background-color: #222222;

		border-right: 1px solid #222222;

		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);

		.footer {
			padding: 20px 20px;
			position: relative;
			color: #7f7f7f;
			display: flex;
			flex-direction: column;

			p {
				padding: 0;
				margin: 0;
				margin-bottom: 20px;
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 10px;
				width: calc(100% - 20px);
				height: 0.5px;
				background-color: #2f2f2f;
			}
		}

		.gutter-list {
			list-style-type: none;
			color: white;
			text-align: left;
			padding-top:30px;
			flex-grow: 1;

			.item {
				position: relative;
				height: 70px;
				user-select: none;
				display: flex;
				align-items: center;
				padding-left: 61px;
				transition: 0.25s;
				background-color: transparent;
				font-size: 14px;
				font-weight: 500;

				opacity: 0.4;

				img {
					padding-right: 12px;
					width: 17px;
					height: 17px;
				}
			}

			.item.selected {
				opacity: 1;
			}

			.item.selected:hover {
				opacity: 1;
				margin-left:0;
				cursor: pointer;
			}

			.item:hover {
				opacity: 1;
				// margin-left:5px;
				cursor: pointer;
			}
		}
	}

	.lib-middle-pane {
		display:flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-y: scroll;

		height: 100%;
		overflow: scroll;
	}

	.lib-right-pane {
		background-color: #222222;
		width: 400px;
		height: 100%;
		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.25);
	}

	.add-video-button {
		outline: 0;
		border: 0;
		height: 45px;
		width: 80%;
		
		background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
		background-size: 400% 400%;
		background-position: 0% 50%;

		border-radius: 100px;

		display:flex;
		align-items: center;
		justify-content:center;

		margin: 0 auto;
		margin-top: 40px;

		font-size: 12px;
		letter-spacing: 0.5px;
		font-weight: 500;

		cursor: pointer;
		font-weight: 700;
		color: white;
		transition: 0.5s;

		img {
			// float:left;
			padding-right:15px;
			// margin-left: -20px;
			height: 15px;
		}
	}

	.add-video-button:hover {
		background-position: 100% 50%;
		color: white;
		-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	}

	.videos-list-container {
		padding: 30px 80px;
		display: flex;
		flex-direction: column;
		height: 100%;
		animation: enterLoaderAnim 500ms normal forwards;

		@keyframes enterEditorAnim {
		  0% {
		    opacity: 0;
		  }
		  100% {
		    opacity: 1.0;
		  }
		}
		
		.videos-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;

			// button {
			// 	text-decoration: none;
			// 	color: white;
			// 	margin: 0;
			// 	margin-bottom:0px;
			// 	margin-right: 10px;
			// 	outline: 0;
			// 	border: 0;
			// 	height: 45px;
			// 	border-radius: 4px;
			// 	cursor: pointer;
			// 	transition: 0.5s;
			// 	font-size: 15px;
			// 	font-weight: bold;
			// 	letter-spacing: 0.5px;
			// 	display: flex;
			// 	justify-content: center;
			// 	align-items: center;
			// 	text-transform: uppercase;
			// 	background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
			// 	background-size: 400% 400%;
			// 	background-position: 0% 50%;
			// 	padding: 0 20px 0 17px;
			// 	border-radius: 100px;
			// 	font-family: "Signika";

			// 	img {
			// 		height: 18px;
			// 		margin-right: 12.5px;
			// 	}
			// }

			// button:hover {
			// 	background-position: 100% 50%;
			// 	color: white;
			// 	-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
			// 	-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
			// 	box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
			// }
		}

		h2 {
			color: white;
			display: flex;
			align-items: center;

			img {
				padding-right: 10px;
			}
		}
	}

	.videos-loading-container {
		width: 100%;
		height: 70vh;
		display: flex;
		flex-direction: column;
		align-items:center;
		justify-content: center;
		color: rgba(255,255,255,0.35);
		font-family: "Signika";

		.zero-state-icon {
			height: 50px;
			opacity: 0.15;
			margin-bottom: 20px;
		}

		h3 {
			padding: 0;
			margin: 0;
			padding-bottom: 10px;
			padding-top: 10px;
			color: white;
		}

		h3.loading {
			padding-bottom: 25px;
			opacity: 0.4;
		}

		p {
			margin: 0;
			padding: 0;
			margin-bottom: 30px;
			font-size: 1.0em;
			max-width:  400px;
			text-align: center;
			line-height: 1.5em;
			color: #7f7f7f;
		}
	}

	.videos-list {
		// position: relative;
		// height: 100%;
		// width: 100%;

		// margin: 0 30px;
		padding: 40px 0 40px 0;
		display: grid;
		grid-gap: 20px;
		// grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
		grid-template-columns: repeat(auto-fill, 200px);
		grid-template-rows: repeat(auto-fill, 250px);

		// cursor: pointer;
		// border: 1px solid red;

		.item {
			color: white;
			border-radius: 10px;
			height: 250px;
			width: 200px;
			max-width: 200px;
			background-color: #222;
			transition: 0.2s;

			overflow: hidden;

			display: flex;
			flex-direction: column;

			.video {
				flex-grow: 1;
				height:20px;
				width: 100%;

				display: flex;
				justify-content: center;
				align-items: center;

				.processing {
					font-size: 14px;
					font-weight: 700;
					color: #444444;
					display: flex;
					justify-content:center;
					align-items: center;

					label {
						margin-left: 5px;
					}
				}

				// video {
				// 	// background-color: red;
				// 	object-fit:cover;
				// }

				.thumbnail-image {
					object-fit:cover;
					height:100%;
					width: 100%;
				}
			}

			.info {
				height: 40px;
				width: 100%;
				background-color: transparent;
				transition: 0.2s;
				padding: 12px 15px 14px 15px;
				// margin-left:30px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.title {
					font-weight: 500;
					font-size: 14px;
					height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-right:40px;
				}

				.updated {
					color: #757575;
					font-size: 12px;
				}
			}
		}

		.item.selected {
			background-color: #3781D8;
			box-shadow: 0px 0px 50px -15px #3781D8;
			.updated {
				color: white;
			}
		}

		.item.selected:hover {
			background-color: #3781D8;
		}


		.item:hover {
			background-color: #2a2a2a;
			cursor: pointer;
		}
	}

}

.add-video-enter {
  opacity: 0;
  transform: scale(0.95);
}
.add-video-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}
.add-video-exit {
  opacity: 1;
  transform: scale(1.0);
}
.add-video-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 200ms, transform 200ms;
}


.lib-right-pane-enter {
  margin-right: -400px;
}
.lib-right-pane-enter-active {
  margin-right: 0px;
  transition: 300ms;
}
.lib-right-pane-exit {
  opacity: 1;
}
.lib-right-pane-exit-active {
  margin-right: -400px;
  transition: 300ms;
}






