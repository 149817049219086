.loading-pane {
	color: rgba(255,255,255,0.35);
	font-family: "Signika";
}

.settings-pane {

	color: white;
	padding: 30px 80px;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: hidden;
	animation: enterLoaderAnim 500ms normal forwards;

	@keyframes enterEditorAnim {
	  0% {
	    opacity: 0;
	  }
	  100% {
	    opacity: 1.0;
	  }
	}

	.overlay-pane {
		margin: 0;
		padding: 0;
		top: 0;
		height: calc(100% - 100px);
	}

	.footer-menu {
		height: 80px;
		width: 100%;
		background-color: #222222;
		position: fixed;
		bottom: 0;
		left: 250px;
		width: calc(100% - 250px);

		display: flex;
		justify-content: space-between;
		align-items: center;

		.cancel-button {
			margin-left: 20px;
			height: 40px;
			padding: 0 20px;
			outline: 0;
			border: 0;
			background-color: #F74743;
			font-size: 11px;
			font-weight: bold;
			color: white;
			cursor: pointer;
			transition: 0.2s;
			border-radius: 4px;
		}

		.save-button {
			margin-right: 20px;
			height: 40px;
			padding: 0 20px;
			outline: 0;
			border: 0;
			background-color: #4A90E2;
			font-size: 11px;
			font-weight: bold;
			color: white;
			cursor: pointer;
			transition: 0.2s;
			pointer-events: all;
			border-radius: 4px;
		}

		.save-button:hover {
			color: #4A90E2;
			background-color: white;
		}

		.save-button.disabled {
			opacity: 0.5;
			background-color: #979797;
			pointer-events: none;
		}
	}

	.settings-content-pane {
		// margin-left: 32px;

		.store-list-container {
			display: flex;
			display: flex;
	        flex-wrap: wrap;

			.item.add {
				height: 25px;
				background-color: transparent;
				border: 2px dashed #5f5f5f;
				text-align: center;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 3px 14px 3px 3px;
				color: #5f5f5f;
				transition: 0.25s;
				cursor: pointer;

				.placeholder-image {
					height: 25px;
					width: 25px;
					background-color: #2f2f2f;
					margin-right: 10px;
					transition: 0.25s;
					border-radius: 300px;
				}
			}

			.item.add:hover {
				color: #e3e3e3;
				border-color: #e3e3e3;
				// border: 2px solid #e3e3e3;

				.placeholder-image {
					background-color: #e3e3e3;
				}
			}

			.item {
				border-radius: 8px;
	        overflow: hidden;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				background-color: #5f5f5f;
				padding: 3px 8px 3px 3px;
				border-radius: 300px;
				font-size: 14px;
				font-weight: bold;
				user-select: none;
				width: fit-content;
				border: 2px solid transparent;
				margin-right: 10px;
				margin-bottom: 10px;

				.delete {
					outline: none;
					border: none;
					background-color: transparent;
					color: white;
					cursor: pointer;
					font-family: bold;
					margin-left: 10px;
					font-size: 12px;
					opacity: 0.25;
					transition: 0.25s;
				}

				.delete:hover {
					opacity: 1;
				}

				img {
					height: 25px;
					width: auto;
					margin-right: 10px;
					background-color: white;
					border-radius: 300px;
				}
			}
		}
	}

	.settings-header {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.account-pane {
		// margin: 30px 0 30px 0;
		// padding-bottom: 20px;
	}

	.account-item {
		margin: 0 0 10px 0;
	}

	label {
		color: rgba(255,255,255,0.5);
		font-size: 12px;
		height: 12px;
		font-weight: 500;
		transition: 0.25s;
	}

	.item-value {
		padding-top: 4px;
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	h2 {
		display: flex;
		align-items: center;

		img {
			padding-right: 10px;
		}
	}

	h4 {
		padding: 0;
		margin: 0;
	}

	hr {
		opacity: 0.2;
	}

	.saving-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.25);
		backdrop-filter: blur(2.5px);
		z-index: 20000;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: all;
		opacity: 1;
		transition: 0.25s;
	}

	.saving-overlay.hidden {
		pointer-events: none;
		opacity: 0;
	}
}