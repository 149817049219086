.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90vh;
	flex-direction: column;
	text-align: center;

	.error-container {
		height: 30px;
		width: 100%;
		position: absolute;
		top: 10px;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.25s;

		.error-message {
			border-radius: 4px;
			padding: 5px 20px;
			background-color: #E73C7E;
			color: white;
			font-size: 14px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				height: 15px;
				width: 15px;
				padding-right: 10px;
			}
			animation: enterErrorAnim 500ms normal forwards;

			@keyframes enterErrorAnim {
			  0% {
			    opacity: 0;
			    margin-top: -100px;
			  }
			  100% {
			    opacity: 1.0;
			    margin-top: 0;
			  }
			}
		}
	}

	.message {
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		max-width: 350px;
		width: 100%;

		.submitButton {
			outline: 0;
			border: 0;
			height: 45px;
			width: 100%;
			padding: 0 20px;
			
			background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
			background-size: 400% 400%;
			background-position: 0% 50%;

			border-radius: 4px;

			display:flex;
			align-items: center;
			justify-content:center;

			margin: 0 auto;
			margin-top: 40px;

			font-size: 12px;
			letter-spacing: 0.5px;
			font-weight: 500;

			cursor: pointer;
			font-weight: 700;
			color: white;
			transition: 0.5s;

			img {
				float:left;
				padding-right:15px;
				margin-left: -20px;
				height: 15px;
			}
		}

		.submitButton:hover {
			background-position: 100% 50%;
			color: white;
			-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
			-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
			box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
		}

		.logo {
			background-image: url('./img/freckle-logo.svg');
			background-position: center;
			background-repeat: no-repeat;

			height: 50px;
			width: 200px;
			margin-bottom: 10px;
		}

		h2{
			padding: 0;
			margin: 0;
		}

		p {
			color: #8f8f8f;
			max-width: 400px;
			line-height: 2em;
			padding: 5px;
			padding-botom: 15px;
			margin: 0;
			font-size: 1em;
		}

		form {
			text-align: left;
			width: 100%;
		}
	}
}