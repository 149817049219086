@import './colors';

.freckle-button {
	outline: 0;
	border: 0;
	height: 35px;
	width: fit-content;
	padding: 0 20px;

	// background: linear-gradient(90deg, #3781D8, #4A90E2, #6EA0DB, #A6CAF4, #F1A1C0, #E73C7E, #E73C7E, #E73C7E);
	// background-size: 400% 400%;
	// background-position: 0% 50%;

	background-color: #3781D8;

	border-radius: 100px;

	display:flex;
	align-items: center;
	justify-content:center;

	font-size: 12px;
	letter-spacing: 0.5px;
	font-weight: 700 !important;

	cursor: pointer;
	color: white;
	transition: 0.5s;

	pointer-events: auto;

	img {
		padding-right:15px;
		height: 15px;
	}

	.left-arrow {
		padding: 0;
		margin-right: 10px;
		height: 6px;
		width: 10px;
	}

	.right-arrow {
		padding: 0;
		margin-left: 10px;
		height: 6px;
		width: 10px;
	}
}

.freckle-button.disabled {
	background: none;
	color: #9f9f9f;
	pointer-events: none;
	background-color: #3f3f3f;
}

.freckle-button:hover {
	// background-position: 100% 50%;
	background-color: #E73C7E;
	color: white;
	-webkit-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	-moz-box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
	box-shadow: 0px 0px 18px 0px rgba(0,0,0,0.5);
}

.freckle-button.back-button {
	padding: 0 20px;
	height: 45px;
	outline: 0;
	border: 0;
	background-color: transparent;
	background: none;
	font-size: 12px;
	font-weight: bold;
	color: white;
	cursor: pointer;
	transition: 0.2s;
	border-radius: 4px;
	text-transform: uppercase;
}

.freckle-button.back-button.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.freckle-button.back-button:hover {
	background-color: #444444;
	box-shadow: none;
}

