@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;600&display=swap');
@import './colors';

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  color: rgba(255,255,255,0.35);
  font-family: "Signika";
  animation: enterLoaderAnim 1000ms normal forwards;

  h4 {
    padding-bottom: 10px;
  }
}

@keyframes enterEditorAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes enterLoaderAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

.Editor {
  text-align: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  font-family: "Signika";
  display:flex;
  flex-direction: column;
  // animation: enterEditorAnim 1000ms normal forwards;

  .keyframes-zero-state {
    height: calc(100vh - 65px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 13px;
    line-height: 22px;
    color: #9f9f9f;
    user-select: none;
  }

  .product-drag-item {
    position: absolute;
    height: 200;
    width: 100;
    background-color: transparent;
    top: 20%;
    left: 50%;
    z-index: 1000000;
    pointer-events: none;

    margin-top: -70px;
    margin-left: -23px;

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 200px;
      border: 4px solid $freckle-light-blue;
      background-color: white;
    }
    span:after {
      content: '';
      position: absolute;
      left: 16px;
      top: 46px;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid $freckle-light-blue;
      clear: both;
    }
  }

  .product-drag-item:after {
    content: '';
    position: absolute;
    left: 15px;
    bottom: -26px;
    width: 5px;
    height: 5px;
    border: 6px solid $freckle-light-blue;
    border-radius: 50%;
    background-color: transparent;
  }


  .overlay-pane {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0,0,0,0.75);
    z-index: 2000;
    opacity: 1;
    pointer-events: default;
    transition: 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-pane.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .products-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.25);
    backdrop-filter: blur(2.5px);
    z-index: 200;
    opacity: 1;
    pointer-events: default;
    transition: 0.5s;
  }

  .products-backdrop.hidden {
    pointer-events: none;
    opacity: 0;
  }

  .main-content {
    display:flex;
    flex-direction: row;
    flex-grow: 1;
    // max-width: 100vw;
    // overflow: hidden;
  }

  .prod-pane {
    width: 270px;
    height: 100%;
    background-color: #222222;
    margin-right: 2px;
    overflow: visible;
    position: relative;

    animation: prod-pane-enterProdAnim 300ms normal forwards;

    @keyframes prod-pane-enterProdAnim {
        0% {
            margin-left: -270px;
        }
        100% {
            margin-left: 0px;
        }
    }
  }

  .prod-pane.exit {
    animation: prod-pane-exitAnim 300ms normal forwards;

    @keyframes prod-pane-exitAnim {
        0% {
            margin-left: 0;
        }
        100% {
            margin-left: -270px;
        }
    }
  }

  .center-pane {
    display:flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
  }

  .right-pane {
    width: 300px;
    min-width: 300px;
    height: 100%;
    background-color: #222222;
    margin-left: 2px;
    flex: 0;

    animation: right-pane-enterAnim 300ms normal forwards;

    @keyframes right-pane-enterAnim {
        0% {
            margin-right: -300px;
        }
        100% {
            margin-right: 0px;
        }
    }

    .product-header {
      height: 90px;
      background-color: #1E1E1E;
      display: flex;
      flex-direction: row;
      color: white;
      min-width: 0;
      // border-bottom: 1px solid #111111;
      box-shadow: rgba(0,0,0,0.15) 0 2px 10px;
      position: sticky;

      img {
        height: 50px;
        width: 50px;
        margin: 20px;
        margin-right: 10px;
        background-color: white;
        object-fit: cover;
        border-radius: 6px;
      }

      .trash-container {
        height: 30px;
        width: 30px;
        background-color: transparent;
        opacity: 0.45;
        cursor: pointer;
        transition: 0.25s;
        flex: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        margin-right: 10px;

        .trash-icon {
          border-radius: 0;
          object-fit: fill;
          background-color: transparent;
          height:14px;
          width: 14px;
          margin: 0 15px;
          padding: 0;
        }
      }

      .trash-container:hover {
        opacity: 1;
      }

      .product-header-details {
        margin: 15px 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        flex-grow: 1;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 0;

        .product-title {
          font-size: 0.9em;
          font-weight: 700;
          min-width: 0;
          width: 100%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .product-price {
          font-size: 0.9em;
          color: #555555;
          min-width: 0;
        }
      }

    }
  }

  .right-pane.exit {
    width: 300px;
    animation: right-pane-exitAnim 300ms normal forwards;

    @keyframes right-pane-exitAnim {
        0% {
            margin-right: 0px;
        }
        100% {
            margin-right: -300px;
        }
    }
  }


.tutorial-enter {
  opacity: 0;
}
.tutorial-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.tutorial-exit {
  opacity: 1;
}
.tutorial-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}


.drag-freckle-enter {
  opacity: 0;
}
.drag-freckle-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.drag-freckle-exit {
  opacity: 1;
}
.drag-freckle-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

  .stage-panel-enter {
    margin-bottom: calc(min(-20vh, -300px));
  }
  .stage-panel-enter-active {
    margin-bottom: 0;
    transition: 300ms;
  }
  .stage-panel-exit {
    opacity: 1;
  }
  .stage-panel-exit-active {
    opacity: 0;
    margin-bottom: calc(min(-20vh, -300px));
    transition: 300ms;
  }


  .keyframe-panel-enter {

  }
  .keyframe-panel-enter-active {
    transition: opacity 50ms, transform 50ms;
  }
  .keyframe-panel-exit {
    opacity: 1;
  }
  .keyframe-panel-exit-active {
    transition: opacity 50ms, transform 50ms;
  }



  .product-panel-enter {
    
  }
  .product-panel-enter-active {
    opacity: 1;
    transition: opacity 300ms, transform 300ms;
  }
  .product-panel-exit {
    opacity: 1;
  }
  .product-panel-exit-active {
    transition: opacity 300ms, transform 300ms;
  }

}


