@import './colors';

.main-container {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	height: calc(100vh);
	animation: enterLoaderAnim 500ms normal forwards;

	@keyframes enterEditorAnim {
	  0% {
	    opacity: 0;
	  }
	  100% {
	    opacity: 1.0;
	  }
	}

	.library {
		width: 400px;
		height: 100%;
		background-color: #222222;

		h3 {
			color:white;
			margin: 0;
			padding-bottom: 5px;
		}

		.price-label {
			color: #888888;
			font-size:12px;
			padding-top:3px;
			padding-bottom: 5px;
		}

		.header-image {
			width: 100%;
			min-height: 20px;
			max-height: 400px;
			object-fit: cover;
			pointer-events: none;
			margin-bottom: 20px;
		}

		.details {
			margin: 0 20px;

			.product-options-list {
				padding-top: 20px;
				
				img {
					height: 15px;
					width: 14px;
					float: left;
					padding: 0 10px 0 5px;
				}

				.item {
					text-decoration: none;
					display: flex;
					align-items: center;
					height: 40px;
					line-height: 40px;
					border-top: 1px solid #333333;
					background-color: transparent;
					outline: 0;
					cursor: pointer;
					// color: #777777;
					color: white;
					opacity: 0.4;
					font-size: 13px;
					transition: 0.25s;
				}

				.item:hover {
					opacity: 1.0;
				}
			}
		}
	}

	.library-enter {
	  margin-right: -400px;
	}
	.library-enter-active {
	  margin-right: 0px;
	  transition: 300ms;
	}
	.library-exit {
	  opacity: 1;
	}
	.library-exit-active {
	  margin-right: -400px;
	  transition: 300ms;
	}

	.products-pane {
		color: white;
		padding: 30px 20px 0 80px;
		display: flex;
		flex-direction: column;
		position: relative;
		overflow: hidden;
		font-family: "Signika";
		flex-grow: 1;
		min-width: 500px;
		overflow-y: scroll;


		.products-loading-container {
			width: 100%;
			height: 80%;
			display: flex;
			flex-direction: column;
			align-items:center;
			justify-content: center;
			color: rgba(255,255,255,0.35);
			font-family: "Signika";

			h4 {
				padding: 0;
				margin: 0;
				padding-bottom: 10px;
				color: white;
			}

			h4.loading {
				padding-bottom: 25px;
				opacity: 0.4;
			}

			p {
				margin: 0;
				padding: 0;
				font-size: 0.9em;
			}
		}

		.products-content-pane-container {
			height: 100%;

			.products-content-pane {
				overflow-x: display;
				// margin: 0 30px;
				padding: 40px 0 40px 0;
				display: grid;
				grid-gap: 20px;
				grid-template-columns: repeat(auto-fill, 175px);

				.empty-state {
					text-align: center;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					margin: 0 auto;

					h4 {
						padding: 0;
						margin: 0;
						padding-bottom: 10px;
						color: white;
					}

					h4.loading {
						padding-bottom: 25px;
						opacity: 0.4;
					}

					p {
						margin: 0;
						padding: 0;
						font-size: 0.9em;
					}
				}

				.item {
					color: white;
					border-radius: 10px;
					height: 250px;
					background-color: #222222;
					transition: 0.2s;

					overflow: hidden;

					display: flex;
					flex-direction: column;

					img {
						flex-grow: 1;
						height:20px;
						width: 100%;
						background-color: white;
						display: flex;
						justify-content: center;
						align-items: center;
						object-fit: cover;
					}

					.info {
						height: 40px;
						width: 100%;
						background-color: transparent;
						transition: 0.2s;
						padding: 12px 15px 12px 15px;
						// margin-left:30px;
						display: flex;
						flex-direction: column;
						justify-content: center;

						.title {
							font-weight: 500;
							font-size: 14px;
							height: 20px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							margin-right:40px;
						}

						.updated {
							color: #757575;
							font-size: 12px;
						}
					}
				}

				.item:hover {
					background-color: #333333;
					cursor: pointer;
				}

				.item.selected {
					background-color: $freckleblue;
					box-shadow: 0px 0px 50px -15px #3781D8;
					.updated {
						color: white;
					}
				}
			}
		}

		.products-header {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;

			button {
				font-family: "Signika";
				font-weight: medium;
				font-size: 12px;
				margin-left: 15px;
				height: 25px;
				padding: 0 10px;
				border-radius: 400px;
				cursor: pointer;
				outline: none;
				border: none;
				color: white;
				background-color: #2F2F2F;
				opacity: 0.8;
				transition: 0.25s;

				img {
					height: 14px;
					float: left;
					margin-right: 8px;
				}
			}

			button:hover {
				opacity: 1;
				background-color: #3781D8;
			}

		}

		h2 {
			display: flex;
			align-items: center;
			padding-right:30px;

			img {
				padding-right: 10px;
				height: 18px;
			}
		}

		h4 {
			padding-top:15px;
		}

		hr {
			opacity: 0.2;
		}
	}

}